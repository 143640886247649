.pc_banner,.pc_banner2{
  width: 100%;
  height: 600px;
  background: linear-gradient(87deg, #0FC9B7 0%, #009688 100%);
  background-size: cover;
  .swiper{
    .swiper-wrapper{
      .swiper-slide{
        height:600px;
        width: 100%;
        overflow: hidden;
        .item,.item2,.item3,.item4,.item5{
          width: 100%;
          position: relative;
          height: 600px;
          .text{
            position: absolute;
            top:145px;
            width: 100%;
            z-index: 9;
            padding:0 15px;
            h1{
              color: #fff;
              font-size: 60px;
              margin-bottom: 10px;
              font-weight: normal;
              text-align: center;
            }
            p{
              font-size: 26px;
              color: #fff;
              margin-bottom: 20px;
              text-align: center;
            }
            a{
              background: #fff;
              text-align: center;
              color: #009688;
              width: 160px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              margin: 0 auto;
              border-radius: 2px;
              &:hover{
                background: #e0fdfd;
              }
            }
          }
          .text2{
            max-width: 1200px;
            left: 50%;
            margin-left: -600px;
            position: relative;
            h1,p,a{
              text-align: left;
              max-width: 55%;
            }
            a{
              margin:0;
            }
          }
          img.leftImg{
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            z-index: 1;
          }
          img.rightImg{
            position: absolute;
            bottom:0;
            right: 0;
            height: 100%;
            z-index: 1;
          }
        }
        .item2{
          img.rightImg{
            position: absolute;
            bottom: 0;
            right:auto;
            height: auto;
            z-index: 1;
            top: 0;
            left: 50%;
            margin-left: -960px;
            max-width: inherit;
          }
        }
        .item3,.item4{
          img.rightImg{
            position: absolute;
            bottom: 0;
            right: auto;
            height: auto;
            z-index: 1;
            top: 0;
            left: 50%;
            margin-left: -960px;
            width: auto;
            max-width: inherit;
          }
        }
        .item5{
          background: url(../jp/cnImg/bannerBg16.png) no-repeat center bottom;
          img.rightImg{
            position: absolute;
            bottom: 0;
            right:auto;
            z-index: 1;
            left: 35%;
            height: 100%;
          }
          .item5-imgH5{
            display: none;
          }
        }
        .item7{
          background: url('../cn/cnImg/bannerR7Bg.png') no-repeat center bottom;
          background-size: contain;
          img.rightImg{
            position: absolute;
            bottom: 0;
            right:auto;
            z-index: 1;
            left: 55%;
            height: 55%;
            top: 20%;
          }
          .item5-imgH5{
            display: none;
          }
        }
        .bannerS1{
          left:-200px;
          opacity: 0;
        }
        .bannerS1C{
          left:0 !important;
          opacity: 1 !important;
        }
        .bannerS0{
          top:100px !important;
          opacity: 0 !important;
        }
        .bannerS0C{
          top:105px !important;
          opacity: 1 !important;
        }
        .bannerS2{
          top:100px !important;
          opacity: 0 !important;
        }
        .bannerS2C{
          top:105px !important;
          opacity: 1 !important;
        }
        .bannerS3{
          left:20% !important;
          opacity: 0;
        }
        .bannerS3C{
          left:50% !important;
          opacity: 1 !important;
        }
        .bannerS4{
          left:50% !important;
          opacity: 0;
        }
        .bannerS4C{
          left:50% !important;
          opacity: 1 !important;
        }
        .bannerS5{
          left:20% !important;
          opacity: 0;
        }
        .bannerS5C{
          left:50% !important;
          opacity: 1 !important;
        }
        .bannerS6{
          left:70% !important;
          opacity: 0;
        }
        .bannerS6C{
          left:50% !important;
          opacity: 1 !important;
        }
      }
      .jzBanner{
        height: 580px;
        width: 100%;
        padding:0 15px;
        background: url("../jp/cnImg/sCt.png") no-repeat center bottom;
        .bannerBox{
          max-width: 1200px;
          margin:0 auto;
          position: relative;
          padding-top:60px;
          height: 580px;
          img{
            position: absolute;
            right:0;
            top:0;
          }
          .text{
            position: absolute;
            top:60px;
            z-index: 3;
            left: -200px;
            opacity: 0;
            .zw{
              width: 50px;
              height: 7px;
              border: 2px solid #FFFFFF;
              margin:30px 0;
            }
            h1{
              padding-bottom: 20px;
              span{
                font-size: 44px;
                color: #fff;
                font-weight: bold;
                display: inline-block;
              }
            }
            h2{
              font-size: 24px;
              display: inline-block;
              background: #FAC00D;
              font-weight: normal;
              color: #333;
              padding:15px;
              border-radius: 8px;
            }
            p{
              color: #fff;
              margin-bottom:20px;
              font-size: 20px;
            }
            .p3{
              font-size: 14px;
            }
            .p4{
              font-size: 22px;
              font-weight: bold;
            }
            a{
              background: #fff;
              text-align: center;
              color: #009688;
              width: 240px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              border-radius: 2px;
              &:hover{
                background: #e0fdfd;
              }
            }
          }
        }
      }
      .swiper-slide_jp{
        .item,.item2,.item3,.item4,.item5{
          .text{
            top:175px;
            h1{
              font-size: 40px;
            }
          }
          .text2{
            top:140px;
            h1{
              font-size: 40px;
            }
          }
        }
        .item7{
          background: url('../jp/cnImg/bannerR7Bg.png') no-repeat center bottom;
          background-size: contain;
          p{
            font-size: 22px !important;
          }
          img.rightImg{
            position: absolute;
            bottom: 0;
            right:auto;
            z-index: 1;
            left: 55%;
            height: 55%;
            top: 20%;
          }
          .item5-imgH5{
            display: none;
          }
        }
      }
      .swiper-slide-active{

      }
    }
    .swiper-pagination{
      bottom:66px;
      .swiper-pagination-bullet{
        background: #fff;
        width: 36px;
        height: 4px;
        border-radius: 4px;
      }
      .swiper-pagination-bullet-active{
        width: 36px;
        height: 4px;
        border-radius: 4px;
      }
    }
  }
}
.news{
  max-width:1200px;
  margin:0 auto;
  height: 60px;
  position: relative;
  padding:0 15px;
  .newsBox{
    position: absolute;
    top: -30px;
    width: calc(100% - 30px);
    left:15px;
    height: 60px;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    background: url("../cn/cnImg/news.png") no-repeat #fff left top;
    background-size: 65px 65px;
    display: flex;
    justify-content: space-evenly;
    padding: 0 20px;
    z-index: 8;
    .newsItem{
      width: 100%;
      overflow: hidden;
      ul{
        height: 60px;
        overflow: hidden;
        li{
          width: 100%;
          height: 60px;
          color:#333;
          font-size: 18px;
          padding:0 15px 0 40px;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          a{
            display: flex;
            height: 60px;
            align-items: center;
            p{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 18px;
              flex: 1;
            }
            &:hover{
              color: #009688;
              span,p{
                color: #009688;
              }
            }
            span{
              font-size: 18px;
              margin-right:10px;
              width: 110px;
            }
            img{
              margin-left:10px;
            }
            &:after{
              content:'';
              position: absolute;
              top: 32px;
              right:0;
              background: #eee;
              width: 1px;
              height:36px;
            }
            &:last-child:after{
              display: none;
            }
          }
        }
      }
    }
    .newsMoreBox{
      position: relative;
      width: 16%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before{
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        background: #eee;
        width: 1px;
        height: 36px;
      }
    }
  }

}
.i_con1{
  max-width:1200px;
  margin:0 auto;
  padding:60px 15px 0 15px;
  h2{
    text-align: center;
    font-size: 40px;
    color:#333;
    padding:15px 0;
  }
  h3{
    font-size: 20px;
    color:#666;
    padding-bottom:30px;
    font-weight: normal;
    text-align: center;
  }
  .a-center-index{

    display: flex;
    justify-content: center;
    a{
      padding: 7px 24px;
      border:1px solid #009688;
      border-radius: 2px;
      margin:0 auto;
      font-size: 18px;
      color: #009688;
      svg{
        margin-left: 8px;
      }
      &:hover{
        background: rgba(0,150,136,.05);
      }
    }
  }
  .searchBox{
    width:50%;
    position: relative;
    height:60px;
    margin:0 auto;
    input{
      background: #F5F5F5;
      border-radius: 2px;
      border:none;
      color:#333;
      width: 100%;
      padding:0 100px 0 50px;
      height:60px;
      line-height: 60px;
      font-size: 18px;
    }
    button{
      width: 100px;
      height: 60px;
      background: #009688;
      color: #fff;
      font-size: 18px;
      position: absolute;
      right:0;
      border:none;
      cursor: pointer;
      &:hover{
        background: #00b6a7;
      }
    }
    svg{
      position: absolute;
      left:18px;
      top:18px;
    }
  }
  .checkBox{
    display: flex;
    justify-content: center;
    padding:20px 0 30px 0;
    label{
      font-size: 14px;
      color:#333;
      margin:0 20px;
      display: flex;
      align-items: center;
      input{
        margin-right:6px;
        width: 16px;
        height: 16px;
        position: relative;
      }
      input[type=checkbox]:checked::before{
        content: '';
        position: absolute;
        top: 2px;
        left: 6px;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        z-index: 1;
      }
      input[type=checkbox]:checked::after{
        position: absolute;
        top: 0;
        background-color: #009688;
        color: #fff;
        width: 16px;
        height: 16px;
        display: inline-block;
        visibility: visible;
        padding-left: 0px;
        text-align: center;
        content: ' ';
        border-radius: 2px;
        box-sizing: border-box;
        border: 1px solid #009688;
      }
    }
  }
  .itemList{
    max-width: 1200px;
    margin:60px auto;
    display: flex;
    justify-content: space-between;
    .item{
      position: relative;
      width: 24%;
      height: 300px;
      .itemBox{
        position: absolute;
        top:0;
        width: 100%;
        background: #FAFCFD;
        padding:30px 20px;
        &:hover{
          background: #FFFFFF;
          box-shadow: 0 2px 30px 0 rgba(169, 186, 212, 0.2);
        }
        img{
          width: 82px;
          margin-bottom:20px;
        }
        h2{
          font-size: 18px;
          color: #333;
          text-align: left;
          font-weight: bold;
          margin-bottom:15px;
          padding:0;
        }
        p{
          color:#666;
          margin: 0 0 20px 0;
        }
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          width: 30px;
          height: 30px;
          background: #009688;
          margin:0;
          transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          svg{
            margin-left:0;
          }
        }
      }
      .itemBox:hover{
        top:-10px;
        a{
          width: 100%;
        }
      }
    }
  }

}
.i_con2 {
  width:100%;
  padding: 60px 15px;
  background: #F5FAFA;
  .i_con2Box{
    max-width: 1200px;
    margin:0 auto;
    h2{
      text-align: center;
      font-size: 40px;
      color:#333;
      padding:15px 0;
    }
    h3{
      font-size: 20px;
      color:#666;
      padding-bottom:30px;
      font-weight: normal;
      text-align: center;
    }
    .itemBox{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top:60px;
      .itemMain,.lastItemMain{
        height:250px;
        position: relative;
        width: 48%;
        margin-bottom:30px;
        a{
          position: absolute;
          height: 100%;
          width: 100%;
          overflow: hidden;
          padding: 30px 0 0 30px;
          background: #fff;
          display: block;
          bottom:0;
          h4{
            font-size: 20px;
            margin-bottom:15px;
          }
          p{
            font-size: 14px;
            line-height: 1.5;
            color:#666;
            margin-bottom: 12px;
          }
          span{
            display: flex;
            align-items: center;
            color:#009688;
            opacity:0;
            img{
              margin-left:5px;
            }
          }
          .rightImg{
            position: absolute;
            width: 40%;
            right:30px;
            bottom:-10px;
            opacity: .3;
          }
          .rightImg2{
            opacity: .3;
            width: 50%;
            right:10px;
            bottom:30px;
          }
        }
        &:hover{
          a{
            bottom:10px;
            background: #F5FAFA;
            box-shadow: 0 2px 30px 0 rgba(169, 186, 212, 0.2);
            span{
              opacity: 1;
            }
            img{
              opacity: 1;
            }
          }
        }
      }
      .jp_h{
        height: 270px;
      }
      .lastItemMain{
        height:110px;
        a{
          img.rightImg{
            width:20%;
          }
        }
      }
    }
  }
}
.i_con3 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 15px;
  h2{
    text-align: center;
    font-size: 40px;
    color:#333;
    padding:15px 0;
  }
  h3{
    font-size: 20px;
    color:#666;
    padding-bottom:30px;
    font-weight: normal;
    text-align: center;
  }
  .itemBox{
    margin-top:40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a.item{
      width: 28%;
      background: #fff;
      text-align: center;
      display: block;
      padding-bottom:20px;
      padding-top:20px;
      .wzImgBox{
        height:280px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width: 75%;
        }
      }
      h4{
        font-size: 18px;
        text-align: center;
        margin:10px 0 15px 0;
        padding:0 15%;
      }
      p{
        font-size: 14px;
        color:#666;
        padding:0 15%;
        text-align: center;
        margin-bottom:20px;
      }
      span{
        width: 32px;
        height: 32px;
        margin:0 auto;
        background: #009688;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        opacity: 0;
      }
      &:hover{
        background: #F0FFFC;
        span{
          opacity: 1;
        }
        .wzImgBox{
          img{
            max-width: 90%;
          }
        }
      }
    }
  }
}
.i_con4 {
  width:100%;
  padding: 60px 0 0 0;
  background: #F5FAFA;
  .i_con4Box{
    max-width: 1200px;
    margin:0 auto;
    h2{
      text-align: center;
      font-size: 40px;
      color:#333;
      padding:15px 0;
    }
    h3{
      font-size: 20px;
      color:#666;
      padding-bottom:30px;
      font-weight: normal;
      text-align: center;
    }
    .itemBox{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top:40px;
      span{
        width: 25%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        &.active{
          border-bottom: 3px solid #009688;
          font-weight: bold;
        }
        &:hover{
          border-bottom: 3px solid #009688;
          font-weight: bold;
        }
      }
    }
  }
  .i_con4Box2{
    width: 100%;
    margin-top:24px;
    height: 400px;
    justify-content: space-between;
    display: none;
    .imgBox{
      width: 50%;
      height: 100%;
      position: relative;
      .itemList{
        width: 100%;
        position: absolute;
        bottom:0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding:20px 0;
        background: rgba(240,255,252,.5);
        img{
          width: 48px;
        }
      }
    }
    .item{
      height: 400px;
      padding-top:100px;
      width: 50%;
      background: linear-gradient(227deg, #00C5B2 0%, #009688 51%, #006A60 100%);
      padding-left:5%;
      h3{
        color: #fff;
        font-size: 26px;
        margin-bottom: 10px;
      }
      p{
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px;
        max-width: 70%;
      }
      a{
        background: #fff;
        border:1px solid #009688;
        color: #009688;
        width: 90px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          background: #F5FAFA;
        }
      }
    }
  }
}
.serveSwiper{
  display: none;
  margin-top:20px;
  .swiper{
    .swiper-wrapper{
      .swiper-slide{
        width: 80%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        left: -5%;
        .imgBox{
          width: 100%;
          height: 116px;
          position: relative;
          .itemList{
            width: 100%;
            position: absolute;
            bottom:0;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding:10px 0;
            background: rgba(240,255,252,.5);
            img{
              width: 38px;
            }
          }
        }
        .item{
          padding:15px;
          width: 100%;
          min-height: 230px;
          position: relative;
          background: linear-gradient(227deg, #00C5B2 0%, #009688 51%, #006A60 100%);
          h3{
            color: #fff;
            font-size: 18px;
            margin-bottom: 10px;
          }
          p{
            font-size: 14px;
            color: #fff;
            margin-bottom: 20px;
            max-width: 100%;
          }
          a{
            background: #fff;
            border:1px solid #009688;
            color: #009688;
            width: 90px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom:15px;
            &:hover{
              background: #F5FAFA;
            }
          }
        }
      }
    }
  }
}
.i_con5 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 15px;
  h2{
    text-align: center;
    font-size: 40px;
    color:#333;
    padding:15px 0;
  }
  h3{
    font-size: 20px;
    color:#666;
    padding-bottom:50px;
    font-weight: normal;
    text-align: center;
  }
  .text{
    display: flex;
    justify-content: space-evenly;
    .item{
      span{
        text-align: center;
        font-size: 40px;
        em{
          font-size: 72px;
        }
      }
      p{
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
.i_con6,.i_con62{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px 60px 15px;
  .swiper {
    width: 100%;
    height: 232px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    padding:0 50px;
  }
}
.i_con7{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px 60px 15px;
  h2{
    text-align: center;
    font-size: 40px;
    color:#333;
    padding:15px 0;
  }
  .itemList{
    margin-top:50px;
    display: flex;
    justify-content: space-evenly;
    a{
      width:214px;
      height: 100px;
      border:1px solid #E8E8E8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 1200px) {
  .pc_banner .swiper .swiper-wrapper {
    .swiper-slide {
      .item2,.item3,.item4,.item5{
        .text2{
          width: 100%;
          margin:0;
          left:10px;
        }
        img.rightImg{
          right: 20px;
          left:45%;
        }
      }
      .item2,.item3,.item4{
        img.rightImg{
          left:50%;
          margin-left:-960px;
        }
      }
      .item5{
        img.rightImg{
          left:25%;
        }
      }
      .bannerS1{
        left:-200px;
        opacity: 0;
      }
      .bannerS1C{
        left:0 !important;
        opacity: 1 !important;
      }
      .bannerS2{
        top:100px !important;
        opacity: 0 !important;
      }
      .bannerS2C{
        top:175px !important;
        opacity: 1 !important;
      }
      .bannerS3{
        left:20% !important;
        opacity: 0;
      }
      .bannerS3C{
        left:20px !important;
        opacity: 1 !important;
      }
      .bannerS4{
        left:50% !important;
        opacity: 0;
      }
      .bannerS4C{
        left:20px !important;
        opacity: 1 !important;
      }
      .bannerS5{
        left:20% !important;
        opacity: 0;
      }
      .bannerS5C{
        left:20px !important;
        opacity: 1 !important;
      }
      .bannerS6{
        left:50% !important;
        opacity: 0;
      }
      .bannerS6C{
        left:20px !important;
        opacity: 1 !important;
      }
    }
  }
}
@media screen and (max-width: 1100px) {

}
@media screen and (max-width: 880px) {

}
@media screen and (max-width: 830px){
  .pc_banner .swiper .swiper-wrapper .swiper-slide{
    .bannerBox{
      .text{
        text-align: center;
        top:30px;
        h1{
          text-align: center;
          span{
            font-size: 24px;
            display: initial;
          }
        }
        h2{
          font-size: 20px;
          text-align: center;
        }
        p{
          text-align: center;
          font-size: 16px;
          margin-top:20px;
        }
        .zw{
          display: none;
        }
        a{
          margin:0 auto;
        }
      }
      img{
        top: 10px;
        width: 60%;
      }
    }
  }
  .i_con1 {
    .jp_itemList {
      .item {
        .itemBox {
          h2 {
            height: 48px
          }

          p {
            height: 84px;
          }
        }
      }
    }
  }
  .pc_banner .swiper .swiper-wrapper {
    .swiper-slide {
      .item2,.item3,.item4,.item5,.item7{
        .text2{
          width: 100%;
          left:20px;
          margin:0;
        }
        img.rightImg{
          right: 20px;
          left:20%;
        }
      }
      .item5{
        img.rightImg{
          left:25%;
        }
      }
      .bannerS1{
        left:-200px;
        opacity: 0;
      }
      .bannerS1C{
        left:0 !important;
        opacity: 1 !important;
      }
      .bannerS2{
        top:30px !important;
        opacity: 0 !important;
      }
      .bannerS2C{
        top:30px !important;
        opacity: 1 !important;
      }
      .bannerS3{
        left:20% !important;
        opacity: 0;
      }
      .bannerS3C{
        left:0 !important;
        opacity: 1 !important;
      }
      .bannerS4{
        left:50% !important;
        opacity: 0;
      }
      .bannerS4C{
        left:0 !important;
        opacity: 1 !important;
      }
      .bannerS5{
        left:20% !important;
        opacity: 0;
      }
      .bannerS5C{
        left:0 !important;
        opacity: 1 !important;
      }
      .bannerS6{
        left:50% !important;
        opacity: 0;
      }
      .bannerS6C{
        left:0 !important;
        opacity: 1 !important;
      }
    }
  }
}
@media screen and (max-width: 830px) {
  .pc_banner {
    height: 460px;
    background: url("../cn/cnImg/bannerBg2.png") no-repeat center center;
    background-size: cover;
    .swiper{
      .swiper-wrapper{
        .swiper-slide{
          height: 460px;
          .item{
            height: 460px;
            .text,.text2,.text3,.text4,.text5,.item7{
              padding:0 10%;
              top:30px;
              left:0;
              h1{
                font-size: 28px;
                width: 100%;
                max-width: 100%;
                text-align: center;
              }
              p,a{
                font-size: 14px;
                width: 100%;
                max-width: 100%;
                text-align: center;
              }
              a{
                width: 88px;
                height: 32px;
                margin:0 auto;
              }
            }
            img.leftImg,img.rightImg{
              height: 60%;
            }
          }
          .item5{
            background: url(../jp/cnImg/bannerBg16H5.png) no-repeat center;
            img.rightImg{
              left: 50%;
              bottom: 0;
              top: auto;
              width: auto;
              max-width: initial;
              right: inherit;
              margin-left: -230px;
            }
            .item5-imgH5{
              display: initial;
            }
            .item5-imgPC{
              display: none;
            }
          }
          .item2{
            img.rightImg{
              left: 50%;
              bottom: 0;
              top: inherit;
              margin-left: -600px;
              max-width: inherit;
            }
          }
          .item3{
            img.rightImg{
              left: 50%;
              bottom: 0;
              top: inherit;
              max-width: inherit;
              margin-left: -600px;
            }
          }
          .item4{
            img.rightImg{
              left: 50%;
              bottom: 0;
              top: inherit;
              max-width: inherit;
              margin-left: -600px;
            }
          }
          .item7{
            .text2{
              top:3% !important;
              padding:0 5% !important;
            }
            .cnt{
              top:6% !important;
            }
            img.rightImg{
              left: 9% !important;
              bottom: 7%;
              height: 42%;
              top:auto;
            }
            h1{
              font-size: 22px !important;
              width: 100%;
              max-width: 100%;
              text-align: center;
            }
            p,a{
              font-size: 14px !important;
              width: 100%;
              max-width: 100%;
              text-align: center;
            }
            a{
              font-size: 0.875rem !important;
              width: 150px !important;
              height: 40px;
            }
          }
        }
      }
    }
  }
  .news {
    height: 130px;
    display: flex;
    .newsBox{
      flex-wrap: wrap;
      flex: 1;
      background: url("../cn/cnImg/news2.svg") no-repeat #fff right top;
      height: auto;
      .newsItem{
        height:160px;
        ul{
          height: 160px;
          li{
            height: auto;
            padding:0;
            a{
              height: auto;
              width: 100%;
              justify-content: flex-start;
              padding:15px 0;
              font-size: 14px;
              border-bottom:1px solid #eee;
              span,p{
                font-size: 14px;
              }
              &:after{
                display: none;
              }
              p{
                width: 160px;
                flex: none;
              }
            }
          }
        }
      }
      .newsMoreBox{
        width: 100%;
        padding:10px 0;
        height:auto;
        display: flex;
        align-content: center;
        justify-content: center;
        img{
          margin-left:8px;
        }
        &:before{
          display: none;
        }
      }
    }
  }
  .i_con1{
    .searchBox{
      width: 100%;
      input{
        font-size: 14px;
      }
    }
    h2{
      font-size: 20px;
    }
    h3{
      font-size: 12px;
    }
    .itemList{
      flex-wrap: wrap;
      margin:30px auto 0 auto;
      .item{
        width: 48%;
        .itemBox{
          padding:15px 10px;
          h2{
            font-size: 14px;
          }
          p{
            font-size: 12px;
            height: 60px;
          }
          a{
            width: 100%;
          }
        }
      }
    }
  }
  .i_con2{
    padding:0;
    background: #fff;
    .i_con2Box{
      h2{
        font-size: 20px;
      }
      h3{
        font-size: 12px;
      }
      .itemBox{
        padding-top: 0;
        .itemMain{
          width: 100%;
          height: 230px;
          a{
            background: #F3FFFD;
            padding:20px;
            h4{
              font-size: 14px;
              font-weight: bold;
            }
            p{
              font-size: 12px;
            }
            span{
              opacity: 1;
              font-weight: bold;
            }
            img.rightImg{
              opacity: 1;
              bottom: 0;
              right: 20px;
            }
            img.rightImg2{
              bottom: 55px;
              right: 10px;
              width: 40%;
            }
            .jp_rightImg3{width: 35%}
          }
        }
        .lastItemMain{
          height: 110px;
        }
      }
    }
  }
  .i_con3{
    padding:0 15px;
    h2{
      font-size: 20px;
    }
    h3{
      font-size: 12px;
    }
    .itemBox{
      margin:0;
      flex-wrap: wrap;
      a.item{
        width: 100%;
        h4{
          margin-top:0;
        }
        span{
          opacity: 1;
        }
      }
    }
  }
  .i_con4{
    padding:10px 15px;
    background: #fff;
    .i_con4Box{
      h2{
        font-size: 20px;
      }
      h3{
        font-size: 12px;
        padding:0;
      }
      .itemBox{
        display: none;
      }
    }
    .i_con4Box2{
      display: none !important;
    }
  }
  .serveSwiper{
    display: block;
  }
  .i_con5{
    padding: 10px 15px 30px 15px;
    h2{
      font-size: 20px;
    }
    h3{
      font-size: 12px;
      padding-bottom: 10px;
    }
    .text{
      .item{
        span{
          display: flex;
          justify-content: center;
          align-items: center;
          em{
            font-size: 40px;
          }
          font-size: 28px;
        }
        p{
          font-size:16px ;
        }
      }
    }
  }
  .i_con6{
    padding-bottom:20px;
  }
  .i_con7{
    padding: 0 15px 30px 15px;
    h2{
      font-size: 20px;
      padding: 25px 0 15px 0;
    }
    .itemList{
      margin-top:20px;
      flex-wrap: wrap;
      justify-content: space-between;
      a{
        width: 48%;
        margin-bottom:20px;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .pc_banner .swiper .swiper-wrapper .swiper-slide{
    .bannerBox{
      .text{
        h1{
          span{
            font-size: 24px;
          }
        }
        h2{
          font-size: 18px;
        }
        .zw{
          margin:20px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text{
    top:30px;
    a{
      font-size: 14px;
      width: 160px;
      height: 40px;
    }
  }
  .serveSwiper .swiper .swiper-wrapper .swiper-slide .item .jp_p_index{
    height: 230px;
  }
}
@media screen and (max-width: 481px) {

}
