.pc_banner,
.pc_banner2 {
  width: 100%;
  height: 600px;
  background: linear-gradient(87deg, #0FC9B7 0%, #009688 100%);
  background-size: cover;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide {
  height: 600px;
  width: 100%;
  overflow: hidden;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 {
  width: 100%;
  position: relative;
  height: 600px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text {
  position: absolute;
  top: 145px;
  width: 100%;
  z-index: 9;
  padding: 0 15px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text h1 {
  color: #fff;
  font-size: 60px;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: center;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text p {
  font-size: 26px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text a {
  background: #fff;
  text-align: center;
  color: #009688;
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0 auto;
  border-radius: 2px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text a:hover,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text a:hover,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text a:hover,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text a:hover,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text a:hover,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text a:hover,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text a:hover,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text a:hover,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text a:hover,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text a:hover {
  background: #e0fdfd;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text2 {
  max-width: 1200px;
  left: 50%;
  margin-left: -600px;
  position: relative;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text2 p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2 p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text2 p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2 p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text2 p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2 p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text2 p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2 p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text2 p,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text2 a {
  text-align: left;
  max-width: 55%;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 .text2 a,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2 a,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .text2 a {
  margin: 0;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item img.leftImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item img.leftImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.leftImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 img.leftImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.leftImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 img.leftImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.leftImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 img.leftImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.leftImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 img.leftImg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item img.rightImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 1;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg {
  position: absolute;
  bottom: 0;
  right: auto;
  height: auto;
  z-index: 1;
  top: 0;
  left: 50%;
  margin-left: -960px;
  max-width: inherit;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
.pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg {
  position: absolute;
  bottom: 0;
  right: auto;
  height: auto;
  z-index: 1;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: auto;
  max-width: inherit;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 {
  background: url(../jp/cnImg/bannerBg16.png) no-repeat center bottom;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg {
  position: absolute;
  bottom: 0;
  right: auto;
  z-index: 1;
  left: 35%;
  height: 100%;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .item5-imgH5,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item5 .item5-imgH5 {
  display: none;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item7,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item7 {
  background: url('../cn/cnImg/bannerR7Bg.png') no-repeat center bottom;
  background-size: contain;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item7 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item7 img.rightImg {
  position: absolute;
  bottom: 0;
  right: auto;
  z-index: 1;
  left: 55%;
  height: 55%;
  top: 20%;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .item7 .item5-imgH5,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .item7 .item5-imgH5 {
  display: none;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS1 {
  left: -200px;
  opacity: 0;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS1C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS1C {
  left: 0 !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS0,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS0 {
  top: 100px !important;
  opacity: 0 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS0C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS0C {
  top: 105px !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS2 {
  top: 100px !important;
  opacity: 0 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS2C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS2C {
  top: 105px !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS3,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS3 {
  left: 20% !important;
  opacity: 0;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS3C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS3C {
  left: 50% !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS4,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS4 {
  left: 50% !important;
  opacity: 0;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS4C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS4C {
  left: 50% !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS5,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS5 {
  left: 20% !important;
  opacity: 0;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS5C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS5C {
  left: 50% !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS6,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS6 {
  left: 70% !important;
  opacity: 0;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS6C,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide .bannerS6C {
  left: 50% !important;
  opacity: 1 !important;
}
.pc_banner .swiper .swiper-wrapper .jzBanner,
.pc_banner2 .swiper .swiper-wrapper .jzBanner {
  height: 580px;
  width: 100%;
  padding: 0 15px;
  background: url("../jp/cnImg/sCt.png") no-repeat center bottom;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
  height: 580px;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox img,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox img {
  position: absolute;
  right: 0;
  top: 0;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text {
  position: absolute;
  top: 60px;
  z-index: 3;
  left: -200px;
  opacity: 0;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text .zw,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text .zw {
  width: 50px;
  height: 7px;
  border: 2px solid #FFFFFF;
  margin: 30px 0;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text h1,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text h1 {
  padding-bottom: 20px;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text h1 span,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text h1 span {
  font-size: 44px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text h2,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text h2 {
  font-size: 24px;
  display: inline-block;
  background: #FAC00D;
  font-weight: normal;
  color: #333;
  padding: 15px;
  border-radius: 8px;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text p,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text .p3,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text .p3 {
  font-size: 14px;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text .p4,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text .p4 {
  font-size: 22px;
  font-weight: bold;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text a,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text a {
  background: #fff;
  text-align: center;
  color: #009688;
  width: 240px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 2px;
}
.pc_banner .swiper .swiper-wrapper .jzBanner .bannerBox .text a:hover,
.pc_banner2 .swiper .swiper-wrapper .jzBanner .bannerBox .text a:hover {
  background: #e0fdfd;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item2 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item2 .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item3 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item3 .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item4 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item4 .text,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item5 .text,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item5 .text {
  top: 175px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item2 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item2 .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item3 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item3 .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item4 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item4 .text h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item5 .text h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item5 .text h1 {
  font-size: 40px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item2 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item2 .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item3 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item3 .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item4 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item4 .text2,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item5 .text2,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item5 .text2 {
  top: 140px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item2 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item2 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item3 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item3 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item4 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item4 .text2 h1,
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item5 .text2 h1,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item5 .text2 h1 {
  font-size: 40px;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item7,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item7 {
  background: url('../jp/cnImg/bannerR7Bg.png') no-repeat center bottom;
  background-size: contain;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item7 p,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item7 p {
  font-size: 22px !important;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item7 img.rightImg,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item7 img.rightImg {
  position: absolute;
  bottom: 0;
  right: auto;
  z-index: 1;
  left: 55%;
  height: 55%;
  top: 20%;
}
.pc_banner .swiper .swiper-wrapper .swiper-slide_jp .item7 .item5-imgH5,
.pc_banner2 .swiper .swiper-wrapper .swiper-slide_jp .item7 .item5-imgH5 {
  display: none;
}
.pc_banner .swiper .swiper-pagination,
.pc_banner2 .swiper .swiper-pagination {
  bottom: 66px;
}
.pc_banner .swiper .swiper-pagination .swiper-pagination-bullet,
.pc_banner2 .swiper .swiper-pagination .swiper-pagination-bullet {
  background: #fff;
  width: 36px;
  height: 4px;
  border-radius: 4px;
}
.pc_banner .swiper .swiper-pagination .swiper-pagination-bullet-active,
.pc_banner2 .swiper .swiper-pagination .swiper-pagination-bullet-active {
  width: 36px;
  height: 4px;
  border-radius: 4px;
}
.news {
  max-width: 1200px;
  margin: 0 auto;
  height: 60px;
  position: relative;
  padding: 0 15px;
}
.news .newsBox {
  position: absolute;
  top: -30px;
  width: calc(100% - 30px);
  left: 15px;
  height: 60px;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background: url("../cn/cnImg/news.png") no-repeat #fff left top;
  background-size: 65px 65px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
  z-index: 8;
}
.news .newsBox .newsItem {
  width: 100%;
  overflow: hidden;
}
.news .newsBox .newsItem ul {
  height: 60px;
  overflow: hidden;
}
.news .newsBox .newsItem ul li {
  width: 100%;
  height: 60px;
  color: #333;
  font-size: 18px;
  padding: 0 15px 0 40px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.news .newsBox .newsItem ul li a {
  display: flex;
  height: 60px;
  align-items: center;
}
.news .newsBox .newsItem ul li a p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  flex: 1;
}
.news .newsBox .newsItem ul li a:hover {
  color: #009688;
}
.news .newsBox .newsItem ul li a:hover span,
.news .newsBox .newsItem ul li a:hover p {
  color: #009688;
}
.news .newsBox .newsItem ul li a span {
  font-size: 18px;
  margin-right: 10px;
  width: 110px;
}
.news .newsBox .newsItem ul li a img {
  margin-left: 10px;
}
.news .newsBox .newsItem ul li a:after {
  content: '';
  position: absolute;
  top: 32px;
  right: 0;
  background: #eee;
  width: 1px;
  height: 36px;
}
.news .newsBox .newsItem ul li a:last-child:after {
  display: none;
}
.news .newsBox .newsMoreBox {
  position: relative;
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news .newsBox .newsMoreBox:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  background: #eee;
  width: 1px;
  height: 36px;
}
.i_con1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 15px 0 15px;
}
.i_con1 h2 {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding: 15px 0;
}
.i_con1 h3 {
  font-size: 20px;
  color: #666;
  padding-bottom: 30px;
  font-weight: normal;
  text-align: center;
}
.i_con1 .a-center-index {
  display: flex;
  justify-content: center;
}
.i_con1 .a-center-index a {
  padding: 7px 24px;
  border: 1px solid #009688;
  border-radius: 2px;
  margin: 0 auto;
  font-size: 18px;
  color: #009688;
}
.i_con1 .a-center-index a svg {
  margin-left: 8px;
}
.i_con1 .a-center-index a:hover {
  background: rgba(0, 150, 136, 0.05);
}
.i_con1 .searchBox {
  width: 50%;
  position: relative;
  height: 60px;
  margin: 0 auto;
}
.i_con1 .searchBox input {
  background: #F5F5F5;
  border-radius: 2px;
  border: none;
  color: #333;
  width: 100%;
  padding: 0 100px 0 50px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
}
.i_con1 .searchBox button {
  width: 100px;
  height: 60px;
  background: #009688;
  color: #fff;
  font-size: 18px;
  position: absolute;
  right: 0;
  border: none;
  cursor: pointer;
}
.i_con1 .searchBox button:hover {
  background: #00b6a7;
}
.i_con1 .searchBox svg {
  position: absolute;
  left: 18px;
  top: 18px;
}
.i_con1 .checkBox {
  display: flex;
  justify-content: center;
  padding: 20px 0 30px 0;
}
.i_con1 .checkBox label {
  font-size: 14px;
  color: #333;
  margin: 0 20px;
  display: flex;
  align-items: center;
}
.i_con1 .checkBox label input {
  margin-right: 6px;
  width: 16px;
  height: 16px;
  position: relative;
}
.i_con1 .checkBox label input[type=checkbox]:checked::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 1;
}
.i_con1 .checkBox label input[type=checkbox]:checked::after {
  position: absolute;
  top: 0;
  background-color: #009688;
  color: #fff;
  width: 16px;
  height: 16px;
  display: inline-block;
  visibility: visible;
  padding-left: 0px;
  text-align: center;
  content: ' ';
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #009688;
}
.i_con1 .itemList {
  max-width: 1200px;
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
}
.i_con1 .itemList .item {
  position: relative;
  width: 24%;
  height: 300px;
}
.i_con1 .itemList .item .itemBox {
  position: absolute;
  top: 0;
  width: 100%;
  background: #FAFCFD;
  padding: 30px 20px;
}
.i_con1 .itemList .item .itemBox:hover {
  background: #FFFFFF;
  box-shadow: 0 2px 30px 0 rgba(169, 186, 212, 0.2);
}
.i_con1 .itemList .item .itemBox img {
  width: 82px;
  margin-bottom: 20px;
}
.i_con1 .itemList .item .itemBox h2 {
  font-size: 18px;
  color: #333;
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 0;
}
.i_con1 .itemList .item .itemBox p {
  color: #666;
  margin: 0 0 20px 0;
}
.i_con1 .itemList .item .itemBox a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  background: #009688;
  margin: 0;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.i_con1 .itemList .item .itemBox a svg {
  margin-left: 0;
}
.i_con1 .itemList .item .itemBox:hover {
  top: -10px;
}
.i_con1 .itemList .item .itemBox:hover a {
  width: 100%;
}
.i_con2 {
  width: 100%;
  padding: 60px 15px;
  background: #F5FAFA;
}
.i_con2 .i_con2Box {
  max-width: 1200px;
  margin: 0 auto;
}
.i_con2 .i_con2Box h2 {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding: 15px 0;
}
.i_con2 .i_con2Box h3 {
  font-size: 20px;
  color: #666;
  padding-bottom: 30px;
  font-weight: normal;
  text-align: center;
}
.i_con2 .i_con2Box .itemBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 60px;
}
.i_con2 .i_con2Box .itemBox .itemMain,
.i_con2 .i_con2Box .itemBox .lastItemMain {
  height: 250px;
  position: relative;
  width: 48%;
  margin-bottom: 30px;
}
.i_con2 .i_con2Box .itemBox .itemMain a,
.i_con2 .i_con2Box .itemBox .lastItemMain a {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 30px 0 0 30px;
  background: #fff;
  display: block;
  bottom: 0;
}
.i_con2 .i_con2Box .itemBox .itemMain a h4,
.i_con2 .i_con2Box .itemBox .lastItemMain a h4 {
  font-size: 20px;
  margin-bottom: 15px;
}
.i_con2 .i_con2Box .itemBox .itemMain a p,
.i_con2 .i_con2Box .itemBox .lastItemMain a p {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 12px;
}
.i_con2 .i_con2Box .itemBox .itemMain a span,
.i_con2 .i_con2Box .itemBox .lastItemMain a span {
  display: flex;
  align-items: center;
  color: #009688;
  opacity: 0;
}
.i_con2 .i_con2Box .itemBox .itemMain a span img,
.i_con2 .i_con2Box .itemBox .lastItemMain a span img {
  margin-left: 5px;
}
.i_con2 .i_con2Box .itemBox .itemMain a .rightImg,
.i_con2 .i_con2Box .itemBox .lastItemMain a .rightImg {
  position: absolute;
  width: 40%;
  right: 30px;
  bottom: -10px;
  opacity: 0.3;
}
.i_con2 .i_con2Box .itemBox .itemMain a .rightImg2,
.i_con2 .i_con2Box .itemBox .lastItemMain a .rightImg2 {
  opacity: 0.3;
  width: 50%;
  right: 10px;
  bottom: 30px;
}
.i_con2 .i_con2Box .itemBox .itemMain:hover a,
.i_con2 .i_con2Box .itemBox .lastItemMain:hover a {
  bottom: 10px;
  background: #F5FAFA;
  box-shadow: 0 2px 30px 0 rgba(169, 186, 212, 0.2);
}
.i_con2 .i_con2Box .itemBox .itemMain:hover a span,
.i_con2 .i_con2Box .itemBox .lastItemMain:hover a span {
  opacity: 1;
}
.i_con2 .i_con2Box .itemBox .itemMain:hover a img,
.i_con2 .i_con2Box .itemBox .lastItemMain:hover a img {
  opacity: 1;
}
.i_con2 .i_con2Box .itemBox .jp_h {
  height: 270px;
}
.i_con2 .i_con2Box .itemBox .lastItemMain {
  height: 110px;
}
.i_con2 .i_con2Box .itemBox .lastItemMain a img.rightImg {
  width: 20%;
}
.i_con3 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 15px;
}
.i_con3 h2 {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding: 15px 0;
}
.i_con3 h3 {
  font-size: 20px;
  color: #666;
  padding-bottom: 30px;
  font-weight: normal;
  text-align: center;
}
.i_con3 .itemBox {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.i_con3 .itemBox a.item {
  width: 28%;
  background: #fff;
  text-align: center;
  display: block;
  padding-bottom: 20px;
  padding-top: 20px;
}
.i_con3 .itemBox a.item .wzImgBox {
  height: 280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.i_con3 .itemBox a.item .wzImgBox img {
  max-width: 75%;
}
.i_con3 .itemBox a.item h4 {
  font-size: 18px;
  text-align: center;
  margin: 10px 0 15px 0;
  padding: 0 15%;
}
.i_con3 .itemBox a.item p {
  font-size: 14px;
  color: #666;
  padding: 0 15%;
  text-align: center;
  margin-bottom: 20px;
}
.i_con3 .itemBox a.item span {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: #009688;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  opacity: 0;
}
.i_con3 .itemBox a.item:hover {
  background: #F0FFFC;
}
.i_con3 .itemBox a.item:hover span {
  opacity: 1;
}
.i_con3 .itemBox a.item:hover .wzImgBox img {
  max-width: 90%;
}
.i_con4 {
  width: 100%;
  padding: 60px 0 0 0;
  background: #F5FAFA;
}
.i_con4 .i_con4Box {
  max-width: 1200px;
  margin: 0 auto;
}
.i_con4 .i_con4Box h2 {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding: 15px 0;
}
.i_con4 .i_con4Box h3 {
  font-size: 20px;
  color: #666;
  padding-bottom: 30px;
  font-weight: normal;
  text-align: center;
}
.i_con4 .i_con4Box .itemBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 40px;
}
.i_con4 .i_con4Box .itemBox span {
  width: 25%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.i_con4 .i_con4Box .itemBox span.active {
  border-bottom: 3px solid #009688;
  font-weight: bold;
}
.i_con4 .i_con4Box .itemBox span:hover {
  border-bottom: 3px solid #009688;
  font-weight: bold;
}
.i_con4 .i_con4Box2 {
  width: 100%;
  margin-top: 24px;
  height: 400px;
  justify-content: space-between;
  display: none;
}
.i_con4 .i_con4Box2 .imgBox {
  width: 50%;
  height: 100%;
  position: relative;
}
.i_con4 .i_con4Box2 .imgBox .itemList {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  background: rgba(240, 255, 252, 0.5);
}
.i_con4 .i_con4Box2 .imgBox .itemList img {
  width: 48px;
}
.i_con4 .i_con4Box2 .item {
  height: 400px;
  padding-top: 100px;
  width: 50%;
  background: linear-gradient(227deg, #00C5B2 0%, #009688 51%, #006A60 100%);
  padding-left: 5%;
}
.i_con4 .i_con4Box2 .item h3 {
  color: #fff;
  font-size: 26px;
  margin-bottom: 10px;
}
.i_con4 .i_con4Box2 .item p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  max-width: 70%;
}
.i_con4 .i_con4Box2 .item a {
  background: #fff;
  border: 1px solid #009688;
  color: #009688;
  width: 90px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.i_con4 .i_con4Box2 .item a:hover {
  background: #F5FAFA;
}
.serveSwiper {
  display: none;
  margin-top: 20px;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  left: -5%;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .imgBox {
  width: 100%;
  height: 116px;
  position: relative;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .imgBox .itemList {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  background: rgba(240, 255, 252, 0.5);
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .imgBox .itemList img {
  width: 38px;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .item {
  padding: 15px;
  width: 100%;
  min-height: 230px;
  position: relative;
  background: linear-gradient(227deg, #00C5B2 0%, #009688 51%, #006A60 100%);
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .item h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .item p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
  max-width: 100%;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .item a {
  background: #fff;
  border: 1px solid #009688;
  color: #009688;
  width: 90px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px;
}
.serveSwiper .swiper .swiper-wrapper .swiper-slide .item a:hover {
  background: #F5FAFA;
}
.i_con5 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 15px;
}
.i_con5 h2 {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding: 15px 0;
}
.i_con5 h3 {
  font-size: 20px;
  color: #666;
  padding-bottom: 50px;
  font-weight: normal;
  text-align: center;
}
.i_con5 .text {
  display: flex;
  justify-content: space-evenly;
}
.i_con5 .text .item span {
  text-align: center;
  font-size: 40px;
}
.i_con5 .text .item span em {
  font-size: 72px;
}
.i_con5 .text .item p {
  font-size: 20px;
  text-align: center;
}
.i_con6,
.i_con62 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px 60px 15px;
}
.i_con6 .swiper,
.i_con62 .swiper {
  width: 100%;
  height: 232px;
}
.i_con6 .swiper-slide,
.i_con62 .swiper-slide {
  background-position: center;
  background-size: cover;
  padding: 0 50px;
}
.i_con7 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px 60px 15px;
}
.i_con7 h2 {
  text-align: center;
  font-size: 40px;
  color: #333;
  padding: 15px 0;
}
.i_con7 .itemList {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
}
.i_con7 .itemList a {
  width: 214px;
  height: 100px;
  border: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2 {
    width: 100%;
    margin: 0;
    left: 10px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg {
    right: 20px;
    left: 45%;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg {
    left: 50%;
    margin-left: -960px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg {
    left: 25%;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS1 {
    left: -200px;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS1C {
    left: 0 !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS2 {
    top: 100px !important;
    opacity: 0 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS2C {
    top: 175px !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS3 {
    left: 20% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS3C {
    left: 20px !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS4 {
    left: 50% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS4C {
    left: 20px !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS5 {
    left: 20% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS5C {
    left: 20px !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS6 {
    left: 50% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS6C {
    left: 20px !important;
    opacity: 1 !important;
  }
}
@media screen and (max-width: 830px) {
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text {
    text-align: center;
    top: 30px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text h1 {
    text-align: center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text h1 span {
    font-size: 24px;
    display: initial;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text h2 {
    font-size: 20px;
    text-align: center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text p {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text .zw {
    display: none;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text a {
    margin: 0 auto;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox img {
    top: 10px;
    width: 60%;
  }
  .i_con1 .jp_itemList .item .itemBox h2 {
    height: 48px;
  }
  .i_con1 .jp_itemList .item .itemBox p {
    height: 84px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item2 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item3 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item4 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 .text2 {
    width: 100%;
    left: 20px;
    margin: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 img.rightImg {
    right: 20px;
    left: 20%;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg {
    left: 25%;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS1 {
    left: -200px;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS1C {
    left: 0 !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS2 {
    top: 30px !important;
    opacity: 0 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS2C {
    top: 30px !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS3 {
    left: 20% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS3C {
    left: 0 !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS4 {
    left: 50% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS4C {
    left: 0 !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS5 {
    left: 20% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS5C {
    left: 0 !important;
    opacity: 1 !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS6 {
    left: 50% !important;
    opacity: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerS6C {
    left: 0 !important;
    opacity: 1 !important;
  }
}
@media screen and (max-width: 830px) {
  .pc_banner {
    height: 460px;
    background: url("../cn/cnImg/bannerBg2.png") no-repeat center center;
    background-size: cover;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide {
    height: 460px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item {
    height: 460px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text3,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text4,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text5,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .item7 {
    padding: 0 10%;
    top: 30px;
    left: 0;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text h1,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 h1,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text3 h1,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text4 h1,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text5 h1,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .item7 h1 {
    font-size: 28px;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text3 p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text4 p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text5 p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .item7 p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text3 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text4 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text5 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .item7 a {
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text2 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text3 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text4 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .text5 a,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item .item7 a {
    width: 88px;
    height: 32px;
    margin: 0 auto;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item img.leftImg,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item img.rightImg {
    height: 60%;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 {
    background: url(../jp/cnImg/bannerBg16H5.png) no-repeat center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 img.rightImg {
    left: 50%;
    bottom: 0;
    top: auto;
    width: auto;
    max-width: initial;
    right: inherit;
    margin-left: -230px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .item5-imgH5 {
    display: initial;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item5 .item5-imgPC {
    display: none;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item2 img.rightImg {
    left: 50%;
    bottom: 0;
    top: inherit;
    margin-left: -600px;
    max-width: inherit;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item3 img.rightImg {
    left: 50%;
    bottom: 0;
    top: inherit;
    max-width: inherit;
    margin-left: -600px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item4 img.rightImg {
    left: 50%;
    bottom: 0;
    top: inherit;
    max-width: inherit;
    margin-left: -600px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 .text2 {
    top: 3% !important;
    padding: 0 5% !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 .cnt {
    top: 6% !important;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 img.rightImg {
    left: 9% !important;
    bottom: 7%;
    height: 42%;
    top: auto;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 h1 {
    font-size: 22px !important;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 p,
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 a {
    font-size: 14px !important;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .item7 a {
    font-size: 0.875rem !important;
    width: 150px !important;
    height: 40px;
  }
  .news {
    height: 130px;
    display: flex;
  }
  .news .newsBox {
    flex-wrap: wrap;
    flex: 1;
    background: url("../cn/cnImg/news2.svg") no-repeat #fff right top;
    height: auto;
  }
  .news .newsBox .newsItem {
    height: 160px;
  }
  .news .newsBox .newsItem ul {
    height: 160px;
  }
  .news .newsBox .newsItem ul li {
    height: auto;
    padding: 0;
  }
  .news .newsBox .newsItem ul li a {
    height: auto;
    width: 100%;
    justify-content: flex-start;
    padding: 15px 0;
    font-size: 14px;
    border-bottom: 1px solid #eee;
  }
  .news .newsBox .newsItem ul li a span,
  .news .newsBox .newsItem ul li a p {
    font-size: 14px;
  }
  .news .newsBox .newsItem ul li a:after {
    display: none;
  }
  .news .newsBox .newsItem ul li a p {
    width: 160px;
    flex: none;
  }
  .news .newsBox .newsMoreBox {
    width: 100%;
    padding: 10px 0;
    height: auto;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .news .newsBox .newsMoreBox img {
    margin-left: 8px;
  }
  .news .newsBox .newsMoreBox:before {
    display: none;
  }
  .i_con1 .searchBox {
    width: 100%;
  }
  .i_con1 .searchBox input {
    font-size: 14px;
  }
  .i_con1 h2 {
    font-size: 20px;
  }
  .i_con1 h3 {
    font-size: 12px;
  }
  .i_con1 .itemList {
    flex-wrap: wrap;
    margin: 30px auto 0 auto;
  }
  .i_con1 .itemList .item {
    width: 48%;
  }
  .i_con1 .itemList .item .itemBox {
    padding: 15px 10px;
  }
  .i_con1 .itemList .item .itemBox h2 {
    font-size: 14px;
  }
  .i_con1 .itemList .item .itemBox p {
    font-size: 12px;
    height: 60px;
  }
  .i_con1 .itemList .item .itemBox a {
    width: 100%;
  }
  .i_con2 {
    padding: 0;
    background: #fff;
  }
  .i_con2 .i_con2Box h2 {
    font-size: 20px;
  }
  .i_con2 .i_con2Box h3 {
    font-size: 12px;
  }
  .i_con2 .i_con2Box .itemBox {
    padding-top: 0;
  }
  .i_con2 .i_con2Box .itemBox .itemMain {
    width: 100%;
    height: 230px;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a {
    background: #F3FFFD;
    padding: 20px;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a h4 {
    font-size: 14px;
    font-weight: bold;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a p {
    font-size: 12px;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a span {
    opacity: 1;
    font-weight: bold;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a img.rightImg {
    opacity: 1;
    bottom: 0;
    right: 20px;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a img.rightImg2 {
    bottom: 55px;
    right: 10px;
    width: 40%;
  }
  .i_con2 .i_con2Box .itemBox .itemMain a .jp_rightImg3 {
    width: 35%;
  }
  .i_con2 .i_con2Box .itemBox .lastItemMain {
    height: 110px;
  }
  .i_con3 {
    padding: 0 15px;
  }
  .i_con3 h2 {
    font-size: 20px;
  }
  .i_con3 h3 {
    font-size: 12px;
  }
  .i_con3 .itemBox {
    margin: 0;
    flex-wrap: wrap;
  }
  .i_con3 .itemBox a.item {
    width: 100%;
  }
  .i_con3 .itemBox a.item h4 {
    margin-top: 0;
  }
  .i_con3 .itemBox a.item span {
    opacity: 1;
  }
  .i_con4 {
    padding: 10px 15px;
    background: #fff;
  }
  .i_con4 .i_con4Box h2 {
    font-size: 20px;
  }
  .i_con4 .i_con4Box h3 {
    font-size: 12px;
    padding: 0;
  }
  .i_con4 .i_con4Box .itemBox {
    display: none;
  }
  .i_con4 .i_con4Box2 {
    display: none !important;
  }
  .serveSwiper {
    display: block;
  }
  .i_con5 {
    padding: 10px 15px 30px 15px;
  }
  .i_con5 h2 {
    font-size: 20px;
  }
  .i_con5 h3 {
    font-size: 12px;
    padding-bottom: 10px;
  }
  .i_con5 .text .item span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  }
  .i_con5 .text .item span em {
    font-size: 40px;
  }
  .i_con5 .text .item p {
    font-size: 16px ;
  }
  .i_con6 {
    padding-bottom: 20px;
  }
  .i_con7 {
    padding: 0 15px 30px 15px;
  }
  .i_con7 h2 {
    font-size: 20px;
    padding: 25px 0 15px 0;
  }
  .i_con7 .itemList {
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .i_con7 .itemList a {
    width: 48%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text h1 span {
    font-size: 24px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text h2 {
    font-size: 18px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text .zw {
    margin: 20px 0;
  }
}
@media screen and (max-width: 550px) {
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text {
    top: 30px;
  }
  .pc_banner .swiper .swiper-wrapper .swiper-slide .bannerBox .text a {
    font-size: 14px;
    width: 160px;
    height: 40px;
  }
  .serveSwiper .swiper .swiper-wrapper .swiper-slide .item .jp_p_index {
    height: 230px;
  }
}
